import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { SettingOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { selectPeopleNumByDayAndBelongProject, updateSiteNum, getBackgroundImg, uploadBackgroundImg } from '/src/api/attendance';
import { defineComponent, reactive, ref, toRaw, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import upload from '@/components/upload.vue';
export default {
  components: {
    SettingOutlined,
    CloseOutlined,
    upload
  },

  setup() {
    const currentNum = ref(0);

    const getNum = async () => {
      let res = await selectPeopleNumByDayAndBelongProject();

      if (res.code === 200) {
        currentNum.value = res.data;
      } else {
        _message.error(res.message);
      }
    };

    const timer = setInterval(() => {
      getNum();
    }, 60000);
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);

    const returnZero = async () => {
      let res = await updateSiteNum();

      if (res.code === 200) {
        _message.success(res.message);

        currentNum.value = 0;
      } else {
        _message.error(res.message);
      }
    };

    const router = useRouter();

    const returnPreviousPage = () => {
      router.push('/attendance-management/attendance-today');
    };

    const isShowUpload = ref(false);
    const imgUrl = ref('');
    const imgUrl1 = ref('');

    const headerImgUrl = val => {
      // console.log(11112222,val)
      imgUrl.value = val;
    };

    const getImg1 = async () => {
      let res = await getBackgroundImg();

      if (res.code === 200) {
        imgUrl.value = res.data;
        imgUrl1.value = res.data;
      } else {
        _message.error(res.message);
      }
    };

    const getImg = async () => {
      isShowUpload.value = true;
    }; //上传文件名称数据


    const setImg = async () => {
      const res = await uploadBackgroundImg({
        files: imgUrl.value
      });

      if (res.code === 200) {
        _message.success(res.message);

        isShowUpload.value = false;
        imgUrl1.value = imgUrl.value;
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getNum();
      getImg1();
    });
    onUnmounted(() => {
      clearInterval(timer);
    });
    return {
      currentNum,
      returnZero,
      isCompanyAdmin,
      returnPreviousPage,
      isShowUpload,
      headerImgUrl,
      getImg,
      setImg,
      imgUrl,
      imgUrl1
    };
  }

};